.florida .FirstName{
    top: 220px;
    left: 150px;
  }
 

.florida .DOBDay{
    top: 220px;
    left: 400px;
  }
  .florida .Optometrist{
    top: 200px;
    left: 530px;
    font-size: 20px;
    font-weight: 700;
  }
  .florida .Ophthalmologist{
    top: 215px;
    left: 530px;
    font-size: 20px;
    font-weight: 700;
  }
  .transaction-pdf{
    z-index: -10;
    position: absolute;
    left: 2000%;
  }
  .florida .Address{
    left: 310px;
    top: 152px;
  }
  .florida .AptNum{
    top: 735px;
    left: 800px;
  }
  .florida .City{
    top: 152px;
    left: 650px;
  }
  .florida .State{
    top: 780px;
    left: 300px;
  }
  .florida .Country{
    top: 780px;
    left: 400px;
  }
  .florida .Zip{
    top: 780px;
    left: 575px;
  }
  .florida .Date{
    top: 283px;
    left: 100px;
  }
  .florida .Yes{
      left: 80px;
      font-size: 30px;
  }
  .florida .No{
      left: 135px;
      font-size: 30px;
  }
  .florida .Question-One{
    top: 517px;
  }
  .florida .Question-Two{
    top: 534px;
  }
  .florida .Question-Three{
    top: 564px;
  }
  .florida .Question-Four{
      top: 618px;
  }
  .florida .Question-Five{
      top: 634px;
  }
  .florida .Question-Six{
      top: 668px;
  }
  .florida .Question-Seven{
      top: 718px;
  }
  .florida .Question-Eight{
      top: 752px;
  }
  .florida .DoctorName{
    top: 60px;
    left: 450px;
  }
  .florida .DoctorAddress{
    top: 308px;
    left: 440px;
  }
  .florida .DoctorCity{
    top: 980px;
    left: 50px;
  }
  .florida .DoctorState{
    top: 980px;
    left: 360px;
  
  }
  .florida .DoctorCountry{
    top: 980px;
    left: 470px;
  }
  .florida .DoctorZip{
    top: 980px;
    left: 710px;
  }
  .florida .Valid12{
    top: 995px;
    left: 205px;
    font-size: 30px;
  }
  .florida .Valid6{
    top: 995px;
    left: 296px;
    font-size: 30px;
  }
  .florida .DoctorSign{
    font-family: 'Ephesis';
    font-size: 30px;
    top: 273px;
    left: 520px;
    font-weight: 700;
  }
  .florida .DoctorLicense{
    top: 308px;
    left: 180px;
  }
  .florida .UserSign{
    font-family: 'Ephesis';
    font-size: 30px;
    top: 110px;
    left: 260px;
    font-weight: 700;
}
.florida .LicenseNum{
    top: 120px;
    left: 720px;
}
.florida .DoctorPhone{
    top: 330px;
    left: 420px;
}
.florida .LensesIssued{
    top: 360px;
    left: 210px;
}
.florida .AssociateSign{
    font-family: 'Ephesis';
    font-size: 30px;
    top: 350px;
    left: 580px;
    font-weight: 700;
}
.florida .AssociateAddress{
    top: 380px;
    left: 510px;
}
.florida .AssociatePhone{
    top: 403px;
    left: 520px;
}
.florida .TableFirstRow{
    top: 468px;
}
.florida .TableSecondRow{
    top: 488px;
}
.florida .TableFirstRow.first{
    left: 380px;
}
.florida .TableFirstRow.second{
    left: 580px;
}
.florida .TableFirstRow.third{
    left: 770px;
}
.florida .TableSecondRow.first{
    left: 380px;
}
.florida .TableSecondRow.second{
    left: 580px;
}
.florida .TableSecondRow.third{
    left: 770px;
}

