@media only screen and (max-width: 1200px) {
    .NorthCarolina input{
            font-size: 10px;
    }
    .NorthCarolina .FirstName{
        top: 79px;
        left: 100px;
      }
      .NorthCarolina .lastName{
          top: 681px;
          left: 50px;
      }
      .NorthCarolina .DOBDay,
      .NorthCarolina .DOBMonth,
      .NorthCarolina .DOBYear{
        top: 681px;
      }
      .NorthCarolina .DOBDay{
        left: 695px;
      }
      .NorthCarolina .DOBMonth{
        left: 640px;
      }
      .NorthCarolina .DOBYear{
        left: 750px;
      }
      .NorthCarolina .MaleCheck{
        top: 658px;
        left: 797px;
        font-size: 30px;
      }
      .NorthCarolina .FemaleCheck{
        top: 658px;
        left: 835px;
        font-size: 30px;
      }
      .NorthCarolina .Address{
        left: 50px;
        top: 735px;
      }
      .NorthCarolina .AptNum{
        top: 735px;
        left: 800px;
      }
      .NorthCarolina .City{
        top: 780px;
        left: 50px;
      }
      .NorthCarolina .State{
        top: 780px;
        left: 300px;
      }
      .NorthCarolina .Country{
        top: 780px;
        left: 400px;
      }
      .NorthCarolina .Zip{
        top: 780px;
        left: 575px;
      }
      .NorthCarolina .Date{
        top: 1060px;
        left: 520px;
      }
     
      .NorthCarolina .AcuityTestYes{
        top: 793px;
        font-size: 30px;
        left: 500px;
      }
      .NorthCarolina .LenseCheckYes{
        top: 826px;
        left: 348px;
        font-size: 30px;
      }
      .NorthCarolina .LenseCheckNo{
        top: 826px;
        left: 424px;
        font-size: 30px;
      }
      .NorthCarolina .DoctorName{
        top: 130px;
        left: 550px;
      }
      .NorthCarolina .DoctorAddress{
        top: 1020px;
        left: 550px;
      }
      .NorthCarolina .DoctorCity{
        top: 980px;
        left: 50px;
      }
      .NorthCarolina .DoctorState{
        top: 980px;
        left: 360px;
      
      }
      .NorthCarolina .DoctorCountry{
        top: 980px;
        left: 470px;
      }
      .NorthCarolina .DoctorZip{
        top: 980px;
        left: 710px;
      }
      .NorthCarolina .Valid12{
        top: 995px;
        left: 205px;
        font-size: 30px;
      }
      .NorthCarolina .Valid6{
        top: 995px;
        left: 296px;
        font-size: 30px;
      }
      .NorthCarolina .DoctorSign{
        font-family: 'Ephesis';
        font-size: 30px;
        top: 1053px;
        left: 200px;
        font-weight: 700;
      }
      .NorthCarolina .DoctorLicense{
        top: 1020px;
        left: 422px;
      }
      .NorthCarolina .UserSign{
        font-family: 'Ephesis';
        font-size: 25px;
        top: 100px;
        left: 100px;
        font-weight: 700;
    }
    .NorthCarolina .LicenseNum{
        top: 120px;
        left: 410px;
    }
    .NorthCarolina .Parent{
        top: 140px;
        left: 170px;
    }
    .NorthCarolina .Phone{
        top: 140px;
        left:380px;
    }
    .NorthCarolina .Diagnosis{
        top: 180px;
        left: 200px;
        font-weight: 600;
    }
    .NorthCarolina .Question-Two{
        top: 191px;
    }
    .NorthCarolina .Question-Two.One{
        left: 212px;
    }
    .NorthCarolina .Question-Two.Two{
        left: 282px;
    }
    .NorthCarolina .Question-Two.Three{
        left: 354px;
    }
    .NorthCarolina .Question-Three{
        top: 205px;
    }
    .NorthCarolina .Question-Three.One{
        left: 212px;
    }
    .NorthCarolina .Question-Three.Two{
        left: 282px;
    }
    .NorthCarolina .Question-Three.Three{
        left: 330px;
    }
    .NorthCarolina .Question-Three.Four{
        left: 389px;
    }
    .NorthCarolina .TableFirstRow{
        top: 245px;
    }
    .NorthCarolina .TableSecondRow{
        top: 270px;
    }
    .NorthCarolina .first{
        left: 250px;
    }
    .NorthCarolina .second{
        left: 340px;
    }
    .NorthCarolina .third{
        left: 420px;
    }
    .NorthCarolina .Question-Six{
        top: 308px;
        left: 354px;
    }
    .NorthCarolina .Question-Seven{
        top: 324px;
        left: 354px;
    }
    .NorthCarolina .Question-Eight{
        top: 360px;
        font-size: 10px;
    }
    .NorthCarolina .Question-Eight.One{
        left: 140px;
    }
    .NorthCarolina .Question-Eight.Two{
        left: 205px;
    }
    .NorthCarolina .Question-Eight.Three{
        left: 335px;
    }
    .NorthCarolina .Question-Eight.Four{
        left: 400px;
    }
    .NorthCarolina .Question-Eight-Check{
        top: 375px;
        left: 264px;
    }
    .NorthCarolina .Question-Nine {
        top: 400px;
        left: 107px;
        font-weight: 600;
    }
    .NorthCarolina .Question-Ten {
        top: 412px;
        left: 290px;
        font-weight: 600;
    }
    .NorthCarolina .Question-Sixteen{
        font-weight: 600;
        top: 515px;
        left: 107px;
    }
    .NorthCarolina .Question-Seventeen{
        font-weight: 600;
        top: 542px;
        left: 107px;
    }
    .NorthCarolina .Question-Eighteen{
        top: 560px;
        font-weight: 600;
        left: 460px;
    }
    .NorthCarolina .Question-Nineteen{
        top: 622px;
        left: 267px;
        font-weight: 600;
    }
    .NorthCarolina .Question-Nineteen.Six-Months{
        left: 213px;
    }
    .NorthCarolina .Question-Nineteen.Circle{
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        border: 2px solid black;
        left:318px;
        top: 626px;
    }
    .NorthCarolina .DoctorPhone{
        top: 1040px;
        left: 170px;
    }
    .NorthCarolina .DoctorFax{
        top: 1040px;
        left: 470px;
    }
    .NorthCarolina .DoctorName-bottom{
        top: 1020px;
        left: 170px;
    }
    .NorthCarolina .DoctorDeg{
        top: 1020px;
        left: 345px;
    }

}