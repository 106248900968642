.NewYork .FirstName{
    top: 681px;
    left: 330px;
  }
  .NewYork .lastName{
      top: 681px;
      left: 50px;
  }
  .NewYork .DOBDay,
  .NewYork .DOBMonth,
  .NewYork .DOBYear{
    top: 681px;
  }
  .NewYork .DOBDay{
    left: 695px;
  }
  .NewYork .DOBMonth{
    left: 640px;
  }
  .NewYork .DOBYear{
    left: 750px;
  }
  .NewYork .MaleCheck{
    top: 658px;
    left: 797px;
    font-size: 30px;
  }
  .NewYork .FemaleCheck{
    top: 658px;
    left: 835px;
    font-size: 30px;
  }
  .NewYork .MaleCheckSelect{
    top: 678px;
    left: 797px;
    font-size: 30px;
  }
  .NewYork .FemaleCheckSelect{
    top: 678px;
    left: 835px;
    font-size: 30px;
  }
  .transaction-pdf{
    z-index: -10;
    position: absolute;
    left: 2000%;
  }
  .NewYork .Address{
    left: 50px;
    top: 735px;
  }
  .NewYork .AptNum{
    top: 735px;
    left: 800px;
  }
  .NewYork .City{
    top: 780px;
    left: 50px;
  }
  .NewYork .State{
    top: 780px;
    left: 300px;
  }
  .NewYork .Country{
    top: 780px;
    left: 400px;
  }
  .NewYork .Zip{
    top: 780px;
    left: 575px;
  }
  .NewYork .DateM{
    top: 780px;
    left: 720px;
  }
  .NewYork .DateD{
    top: 780px;
    left: 765px;
  }
  .NewYork .DateY{
    top: 780px;
    left: 810px;
  }
  .NewYork .AcuityTestYes{
    top: 793px;
    font-size: 30px;
    left: 500px;
  }
  .NewYork .LenseCheckYes{
    top: 826px;
    left: 348px;
    font-size: 30px;
  }
  .NewYork .LenseCheckNo{
    top: 826px;
    left: 424px;
    font-size: 30px;
  }
  .NewYork .DoctorName{
    top: 890px;
    left: 50px;
  }
  .NewYork .DoctorAddress{
    top: 940px;
    left: 50px;
  }
  .NewYork .DoctorCity{
    top: 980px;
    left: 50px;
  }
  .NewYork .DoctorState{
    top: 980px;
    left: 360px;
  
  }
  .NewYork .DoctorCountry{
    top: 980px;
    left: 470px;
  }
  .NewYork .DoctorZip{
    top: 980px;
    left: 710px;
  }
  .NewYork .Valid12{
    top: 995px;
    left: 205px;
    font-size: 30px;
  }
  .NewYork .Valid6{
    top: 995px;
    left: 296px;
    font-size: 30px;
  }
  .NewYork .DoctorSign{
    font-family: 'Ephesis';
    font-size: 30px;
    top: 1075px;
    left: 200px;
    font-weight: 700;
  }
  .NewYork .DoctorLicense{
    top: 1080px;
    left: 600px;
  }