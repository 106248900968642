@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Bold.ttf) format('woff');
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(./fonts/Quicksand/Quicksand-Light.ttf) format('woff');
}
@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('./fonts/Manrope/Manrope-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'Ephesis';
  src: local('Ephesis'), url('./fonts/Ephesis/Ephesis-Regular.ttf') format('woff');
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*{
  scroll-behavior: smooth;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
:root{
  --main-width: 70vw;
  --light-blue:#D4EFFF ;
  --dark-blue:#3AB9FF ;
  --light-purple: #ACA9F1;
  --dark-purple: #4D4AFF;
  --heading-dark:#2F3759;
  --red: #F16B6B;

}
.btn-big.btn-big.active img{
 filter: brightness(5)
}
.password,.zip,.email{
  position: relative;
}
.password[aria-data="length"]::before{
  content: 'The password must be at least 8 characters long';
}
.password[aria-data="upper"]::before{
  content: 'The password must contain at least 1 capital letter';
}
.password[aria-data="number"]::before{
  content: 'The password must contain at least 1 number';
}
.password[aria-data="lower"]::before{
  content: 'The password must contain at least 1 lowercase letter';
}
.zip[aria-data="length"]::before{
  content: 'Zip code should be exactly 5 numbers long'
}
.zip[aria-data="numbers"]::before{
  content: 'Zip code can only contain numbers'
}
.email[aria-data="invalid"]::before{
  content: 'please enter a valid email address'
}
.phone[aria-data="phone"]::before{
  content:'please enter a valid phone number'
}
.email{
  width: 45%;
  padding: 0;
  margin: 0;
}
.zip{
  width: 45%;
  padding: 0;
  margin: 0;
}
.phone{
  width: 45%;
  padding: 0;
  margin: 0;
  position: relative;
}
.phone::before{
  content:'';
  position: absolute;
  top: -100%;
  border: 2px solid var(--red);
  background: rgba(242, 134, 134, 0.9);
  border-radius: 10px;
  padding: .3rem;    
  transition: .1s ease-in-out;
  transform-origin: left;
  transform: scale(0.9);
  opacity: 0;
}
.phone.invalid::before{
  transform: scale(1);
  opacity: 1;
}
.phone input{
  width: 100%;
}
.email::before{
  content:'';
  position: absolute;
  top: -110%;
  border: 2px solid var(--red);
  background: rgba(242, 134, 134, 0.9);
  border-radius: 10px;
  padding: .3rem;    
  transition: .1s ease-in-out;
  transform-origin: left;
  transform: scale(0.9);
  opacity: 0;
}
.email.invalid::before{
  transform: scale(1);
  opacity: 1;
}
.email input{
  width: 100%;
}
.zip input{
  width: 100%;
}
.zip::before{
  content:'';
  position: absolute;
  top: -110%;
  border: 2px solid var(--red);
  background: rgba(242, 134, 134, 0.9);
  border-radius: 10px;
  padding: .3rem;    
  transition: .1s ease-in-out;
  transform-origin: left;
  transform: scale(0.9);
  opacity: 0;
}
.zip.invalid::before{
  transform: scale(1);
  opacity: 1;
}
.password::before{
  content:'';
  position: absolute;
  top: -110%;
  border: 2px solid var(--red);
  background: rgba(242, 134, 134, 0.9);
  border-radius: 10px;
  padding: .3rem;    
  transition: .1s ease-in-out;
  transform-origin: left;
  transform: scale(0.9);
  opacity: 0;
}
.password.invalid::before{
  transform: scale(1);
  opacity: 1;
}
body{
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(209, 238, 255, 0.75) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
*{
  font-family: 'Manrope';
  margin: 0;
  padding: 0;
}
header{
  width: 73.18181818vw;
  max-width: min(100vw, 1600px);
  padding: 0 2rem 4rem 2rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  margin:3rem auto;
  margin-bottom: 0 !important;
}
.container header{
  position: absolute;
  top: 0;
}
section{
  width: 99vw;
}
.section-3,
.section-4{
  background: white;
}
.header-links{
  min-width: 20%;
  display: flex;
  align-items: center;
  position: relative;
  gap: 4vmin;
}
.header-links p{
  text-transform: capitalize;
  color: var(--dark-purple);
  cursor: pointer;
}
.header-links p svg:first-of-type{
  color:var(--dark-purple);
}
.header-links p svg{
  color:#ACA9F1;
}
.userDropDown{
  background: white;
  position: absolute;
  right: -10%;
 display: flex;
 flex-direction: column;
 padding: 1rem;
 overflow: hidden;
 transition: .2s ease-in-out;
 opacity: 1;
 top: 110%;
 box-shadow: 1px 1px 2px 0px #ccc;
 border-radius: 10px;
}

#certify::before{
  content:'Please certify to continue';
  position: absolute;
  top: -20%;
  padding: .3rem;
  background: white;
  box-shadow:-3px 5px 5px  rgba(59, 135, 176, 0.3);
  transition: .2s ease-in-out;
  transform: scale(0.9);
  transform-origin: top left;
  opacity: 0;
}
#certify.required{
  border: 1px solid red;
}
#certify.required::before{
  top: -15%;
  transform: scale(1);
  opacity: 1;
}
.closed{
  top: 100%;
  opacity: 0;
}
.userDropDown a{
  margin-bottom: .5em;
  color: var(--dark-purple) !important;
}
.header-links .btn-purple{
  padding: 1em 3em;

}
.header-links a{
  color: #1B2033;
  text-decoration: none;
  font-size: 1rem;
}
.App{
  width: 100vw;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.App .form-wrapp{
  width: 65vw;
  max-width: 1270px;
  position: relative;
}
.App .form-wrapp form{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.App .form-wrapp form h1{
  display: block;
  width: 100%;
  margin-bottom: 2em;
}
.App .form-wrapp .left{
  width: 54%;
  height: max-content;
  border-right: 1px solid rgba(0, 0, 0, 0.36);
  display: flex;
  flex-direction: column;
}
.App .form-wrapp .left .form-row{
  width: 90%;
  margin-right: auto;
}
.App .form-wrapp .left .form-row:last-of-type input{
  margin: 0;
}
.App .form-wrapp .right{
  width: 45%;
  display: flex;
  flex-direction: column;
}
.App .form-wrapp .right{
  width: 45%;
  display: flex;
  flex-direction: column;
}
.App .form-wrapp .right .btn-purple{
  margin-top: 3em;
  padding: .7em 5em;
}
.App .form-wrapp .right .form-row{
  width: 90%;
  margin-left: auto;
}
.name-field{
  margin-bottom: 20px;
}
.render-chat{
  width: 40%;
}
.card{
  padding: 2em 5em;
  text-align: center;
}
.sync-card{
  max-width: 100%;
  padding: 2 2em;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.m-sync-card{
  min-height: 40vh;
  min-width: var(--main-width);
  padding: 2em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-family: 'Poppins';
  position: relative;
}

.m-sync-card .c{
  border-radius: 50%;
  padding: 2px 20px;
  font-size: 40px;
}
.m-sync-card .up span{
  display: inline-block;
  transform: rotate(-45deg);
}
.m-sync-card .down span{
  display: inline-block;
  transform: rotate(45deg);
}
.m-sync-card .left span{
  display: inline-block;
  transform: rotate(90deg);
}
.m-sync-card .separate-c{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.sync-item{
  max-width: calc(var(--main-width) / 3.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.sync-item .MuiFormControl-root{
  margin-bottom: 20px;
}
.sync-item h3{
  margin-bottom: 1em;
}
.sync-item .Mui-disabled{
  font-size: 10px;
}
.sync-item .btn-purple{
  padding: .3em 3em;
  font-size: 24px;
}
.wrapper{
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  position: absolute;

}
.test-app{
  min-height: 50vh;
  width: 300%;
  position: relative;
  top: 0;
  left: 0;
}
.test-card{
  width: 50%;
  padding: 2em;
  text-align: center;
}
.test-card h4{
  margin-bottom: 20px;
}
.test-card ul{
  list-style: none;
  margin-bottom: 20px;
}
.slide{
  width: 33%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  transition: .5s ease-in-out;
}
.one-0{
  top:0;
  left: 0;
}
.one-1{
  left:-100%;
}
.one-2{
  left: -200%;
}
.two-0{
  position: absolute;
  top:0;
  left: 100%;
}
.two-1{
  left: 0;
}
.two-2{
  left: -100%;
}
.three-0{
  position: absolute;
  top:0;
  left: 200%;
}
.three-1{
  left: 100%;
}
.three-2{
  left: 0;
}
.landot *{
  font-family: 'Poppins' !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
}
.landot h1{
  display: block;
}
.landot-up{
  transform: rotate(-90deg);
}
.landot-down{
  transform: rotate(90deg);
}
.landot-left{
  transform: rotate(180deg);
}
.transition{
  animation:spin 1s linear infinite;
}
.form-control{
  max-width: 100%;
  margin-bottom: 20px;
  background: var(--light-blue) !important;
  border: none !important;
}
.flag-dropdown{
  border: none !important;
  border-right: 1px solid black !important;
}
.selected-flag{
  background: var(--light-blue) !important;
}
.react-tel-input{
  margin-bottom: 30px;
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.btn-landing{
  margin-bottom: 20px !important;
} 
.feed{
  display: block;
  position: absolute !important;
  top: 50% !important; 
  right: 50% !important;
  transform: translate(50%,-50%) !important;
  z-index: 100;
}
.feedCanvas{
  width: 400px;
  height: 300px;
  position: absolute;
  top: 200%; 
  right: 200%;
  z-index: 20000;
}
.detector{
  position: absolute !important;
  top: 50% !important; 
  right: 50% !important;
  transform: translate(50%,-50%) !important;
  z-index: 1000;
  border:2px solid red;
}
.detector.complete{
  border-color: green !important;
}
.mobileRange{
  display: block !important;
  z-index: 100000000 !important;
  position: absolute !important;
  top: 70vh;
  left: 25vw;
  width: 50vw !important;
  z-index: 10;
}
.video-wrapp{
  width: 100vw;
  max-height: 100vh;
  position: absolute;
  background: #ccc;
  top: 0;
  left: 0;
}
.video-wrapp-mobile{
  width: 100vw;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem 0;
  gap: 2rem;
}
.wrap{
  min-height: 50vh;
  width: 100%;
  display: grid;
  place-items: center;
}
.wrap-acuity{
  min-height: 50vh;
  width: 100%;
}
.wrap img{
  max-width: 510px;
}
.cali-wrap{
  padding: 10rem;
}
.screen-calibration{
  width: 100%;
  height: 100%;
}
.blackWrap{
  height: 100vh;
  width: 100vw;
  position: absolute;
 top: 0;
 left: 0;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.square{
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns:25% 50% 25%;
  grid-template-rows: 5rem 10rem 13rem 1rem 7rem 
  ;
}
.square h2{
  grid-column-start: 2;
  align-self: center;
  text-align: center;
}
.square p{
  grid-column-start: 2;
  grid-row-start: 2;
  text-align: center;
}
.screen-calibration-verification{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: 5rem 5rem 21rem 5rem 8rem;
}
.screen-calibration-verification  h2,
.screen-calibration-verification p  {
  justify-self: center;
}
.screen-calibration-verification .buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  grid-row-start: 5;
}
.screen-calibration-verification > img{
  justify-self: center;
  align-self: center;
}
.screen-calibration-verification .buttons button{
  width: 20%;
  padding: .5rem;
}
canvas{
  z-index: 8;
}
.resize{
  height: 250px;
  width: 400px;
  margin-right: 5rem;
  border: 3px solid var(--light-purple) ;
  transition: .1s ease-in-out;
  transform-origin: top right;
  position: absolute;
  right: 15%;
}
.video-container{
  position: relative;
}
.video-wrapp-mobile p{
  max-width: 30ch;
}
.resizeMobile{
  height: 200px;
  width: 200px;
  transition: .05s ease-in;
  position: absolute;
  z-index: 999999999999;
  transform-origin: center;
  top: 10%;
  left: 16%;
  transform: translate(-50%,-50%);
}
.resizeMobile::before{
  content: '';
  height: 30%;
  width: 30%;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 5px solid #F16B6B;
  border-left: 5px solid #F16B6B;
}
.resizeMobile::after{
  content: '';
  height: 30%;
  width: 30%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #F16B6B;
  border-left: 5px solid #F16B6B;
}
.resize-mobile-fill{
  width: 100%;
  height: 100%;
  position: relative;
}
.resize-mobile-fill::before{
  content: '';
  height: 30%;
  width: 30%;
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5px solid #F16B6B;
  border-right: 5px solid #F16B6B;
}
.resize-mobile-fill::after{
  content: '';
  height: 30%;
  width: 30%;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 5px solid #F16B6B;
  border-right: 5px solid #F16B6B;
}
.square .buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  grid-row-start: 5;
  grid-column-start: 2;
}
.square .lottie{
  grid-row-start: 3;
  grid-column-start: 2;
  height: 100%;
  place-self: center;
}
.square .buttons button{
  width: 30%;
  padding: .5rem;
}
.square .slider{
  grid-column-start: 3;
  grid-row-start: 3;
  justify-self: start;
  position: relative;
  width: 100%;
  height: 100%;
}
.square .slider::before{
  content: '+';
  color: var(--dark-purple);
  font-size: 1.5rem;
  position: absolute;
  left: -0.4rem;
  top: -1rem;
}
.square .slider::after{
  content: '-';
  color: var(--dark-purple);
  font-size: 2rem;
  position: absolute;
  left: -0.4rem;
  bottom: -2rem;
}
 .slide-input{
  transform-origin: left;
  transform: rotate(-90deg) translateX(-150%);
  grid-column-start:2 ;
  place-self: start end;
}
 .slide-input{
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--light-purple) !important;
  height: .5rem;
  width: 100%;
}
 .slide-input::-webkit-slider-thumb{
  -webkit-appearance: none;
  appearance: none;
  aspect-ratio: 1;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--dark-purple) !important;
}

 .slide-input::-moz-range-thumb{
  -webkit-appearance: none;
  appearance: none;
  aspect-ratio: 1;
  outline: none;
  border:none;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--dark-purple) !important;
}
.mobileButton{
  position: absolute !important;
  top: 80vh;
  left: 37vw;
}
.blueSquare{
  height: 200px;
  width: 200px;
  background: var(--dark-purple);
}
.blackSquare{
  background: white;
}
.loader{
  width: 100%;
  height: 100%;
  transition: .1s ease-in-out;
  position: absolute;
  background: white;
  z-index: 9;
}
.loader-text{
  width: 100%;
  height: 100%;
  transition: .1s ease-in-out;
  position: absolute;
  background: white;
  z-index: 9;
  display: grid;
  place-items: center;
}
.loader-off{
    filter: opacity(0);
    z-index: -1;
}
.mini-loader{
  height: 300px;
  width: 300px;
  margin: calc(50vh - 200px) calc(50vw - 150px);
}
.loader-card{
  height:500px;
  width: 500px;
  position: relative;
}
.fail{
  width: 100%;
  height: 100%;
  transition: .1s ease-in-out;
  position: absolute;
  background: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  top: 0
}
.fail-hide{
  filter: opacity(0)
}
.VisualFieldContainer{
  background: #1B2033;
  transition: .2s ease-in-out;
}
.blurred{
  filter: blur(5px);
}
.start{
  z-index: 2;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.start .left{
  height: 60%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 1rem;
}
.start .right{
  height: 60%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 1rem;
}
.start .right .d-flex{
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.start .right .d-flex .blue span{
  display:  inline-block;
  padding: 1em 2em;
  background-color: var(--light-blue);
  border-radius: 20%;
  margin-right: 2em
}
.start .right .d-flex .purple span{
  display:  inline-block;
  padding: 1em 2em;
  background-color: var(--light-purple);
    border-radius: 20%;
    margin-right: 2em
}
.start .left button{
  padding: .5em 2em;
  width: 30%;
}
.start .left ul li ol li{
  margin-left: 2em;
}

.start h1{
  font-size: 5em;
}
.no-display{
  display: none !important;
}
.end{
  background: white;
  z-index: 3;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 111;
}
.end h1{
  color: black;
}
.DepthPerceptionCanvas{
  border-radius: 20px;
  z-index: 100;
}
.col{
  flex-direction: column;
  justify-content: space-evenly;
}
.col-m{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 85vh;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.container{
  scroll-snap-type: y mandatory;
  height: 100vh;
  max-width: 100vw;
 overflow-y: scroll;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif ;
}
section{
  height: 100vh;
  scroll-snap-align: start;
  overflow:hidden
}
.btn-purple{
  background: var(--dark-purple) !important;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
}
.section-1{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(209, 238, 255, 0.75) 100%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
  text-align: center;
}
.section-1 h1{
  font-size: 75px;
  color: #2F3759;;
}
.section-1 h1 span{
  color: var(--dark-blue)
}
.section-1 .btn-purple{
  font-size: 28px;
  padding: .2em 3em;
  cursor: pointer;
}
.section-1 .btn-blue{
  font-size: 28px;
  padding: .2em 3em;
  cursor: pointer;
}
.section-1 h3{
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}
.popup-bar{
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(59, 135, 176, 0.3);
  border-radius: 10px;
  width: 73.18181818vw;
  max-width: 1449px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 1px;
  position: relative;
}
.popup-container{
  float: left;
  width: 33.3%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: calc( 100% + 100px);
}
.popup-container img{
  display: block;
  max-width: 30%;
  aspect-ratio: 1;
  transition: .2s ease-in-out;
}
.popup-container:hover img{
  transform: translateY(-10%);
}
.popup-container p{
  font-size: 20px;
  font-weight: 100;
  text-transform: capitalize;
}
.popup-container .spacer{
  height: 100px;
}
.section-2{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  text-align: center;
  position: relative;
}
.section-2 a{
  z-index: 111;
}
.section-2 .circle{
  position: absolute;
  top: 5vh;
  height: 100vh;
  width: 100vh;
  border-radius: 100%;
  background: linear-gradient(180deg, #D1EEFF 0%, rgba(255, 255, 255, 0) 100%);
}
.section-3 .circle{
  position: absolute;
  top: 5vh;
  height: 100vh;
  width: 100vh;
  border-radius: 100%;
  background: linear-gradient(180deg, #D1EEFF 0%, rgba(255, 255, 255, 0) 100%);
}
.section-3 >p{
  max-width: 50ch;
  text-align: left;
}
.section-2 h1
{
  display: block;
  width: 50%;
  z-index: 2;
}
.section-2 p{
  display: block;
  max-width: 50ch;
  text-align: left;
  z-index: 2;
}
.section-2 h1{
  font-size: 75px;
  color: var(--heading-dark);
}
.section-2 h1 .title{
  font-size: 24px;
  letter-spacing: 0.375em;
}
.section-2 h1 .blue{
  color: #3AB9FF;
}
.section-2 .cont{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-3{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  text-align: center;
  position: relative;
}
.section-3 .gradient{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(90deg, rgba(209, 238, 255, 0) -17.01%, #D1EEFF 50.43%, rgba(209, 238, 255, 0) 117.86%);

}
.section-3 h1{
  display: block;
  margin: 0 5rem;
  width: 60%;
  z-index: 2;
  font-size: 55px;
  color: var(--heading-dark);
}
.section-3 .overlay{
  width: 100%;
}
.section-3 h1 .title{
  font-size: 24px;
  letter-spacing: 0.375em;
}
.section-3 h1 .blue{
  color: #3AB9FF;
}
.section-3 .conte{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.section-3 .form-row{
  width: 100%;
}
.section-3 .img-card{
  z-index: 3;
  background: white;
  min-height: 290px;
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  border-radius: 10px;
}
.section-3 .img-card img{
 display: block;
 max-width: 134px;
}
.section-3 .img-card .holder{
  height: 130px;
}
.section-3 .img-card p{
    font-size: 30px;
    font-weight: 700;
}
.section-3 form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-3 form h1{
  width: 100%;
}
.form-row{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-row input{
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 10px;
  padding-right: 0;
  margin-bottom: 20px;
  transition: .2s ease-in-out;
}
.form-row select{
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 10px;
  padding-right: 0;
  margin-bottom: 20px;
  transition: .2s ease-in-out;
}
.form-row textarea{
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  transition: .2s ease-in-out;
  resize: none;
}
.form-row input:focus{
 outline-color: var(--dark-purple);
}
.form-row select:focus{
  outline-color: var(--dark-purple);
 }
.form-row textarea:focus{
  outline-color: var(--dark-purple);
 }
.input-half{
  width: 45%;
}
.input-short{
  width: 35%;
}
.input-long{
  width: 55%;
}
.section-3 .btn-purple{
  margin-top: 20px;
  font-size: 30px;
  padding: .1em 2em;
  cursor: pointer;
}
.section-4{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items:center;
  text-align: center;
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #D1EEFF 100%);
}
.section-4 .card{
  display: flex;
  width: 50%;
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  background: white;
  border-radius: 11px;
  padding: 2em 3em;
}
.section-4 .card .left{
  max-width: 30%;
  padding: 10px;
  text-align: left;
}
.section-4 .card .left p{
  font-size: 20px;
}
.section-4 .card .right{
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-4 .card .right form{
  max-width: 100%;
}
.section-4 .card .right form .form-row{
    width: 100%;
}
.input-full{
  width: 100%;
}
.section-4 h1{
  font-size: 45px;
  color: var(--heading-dark);
  text-align: left;
}
.section-4 h1 .blue{
  color: #3AB9FF;
}
.section-4 .left p{
  display: block;
  max-width: 100%;
  font-size: 26px;
}
.section-4 .card .right form button{
  font-size: 14px;
  padding: 5px 40px;
}
.section-4 .logo{
  display: block;
  max-width: 100%;
  position: absolute;
  bottom: 5%;
  left: 5%;
}
.privacy-container{
  width: 65vw;
  max-width: 1270px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privacy-nav{
  width: 90%;
  padding: 2rem;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.lnk{
  color: var(--dark-blue);
  text-decoration: underline;
}
.privacy-nav h3{
  cursor: pointer;
}
.terms-privacy{
  width: 50%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  position: relative
}
.terms-privacy h3{
  color: #2F375980;
  text-align: center;
  transition: .1s ease-in-out;
}
.terms-privacy .active{
  color: #1B2033;
}
.terms-privacy::after{
  content: '';
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background: black;
  transform: translateX(0);
  transition: .2s ease-in-out;
}
.priv::after{
  transform: translateX(100%);
  
}
.privacy-container .text-container{
  width: 100%;
  height: 60vh;
  padding-right: 2em;
  overflow-y: scroll;
  font-size: 12px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.privacy-container .text-container::-webkit-scrollbar {
  width: 5px;
}
.privacy-container .text-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.privacy-container .text-container::-webkit-scrollbar-thumb {
  width: 20px;
  border-radius: 10px;
  background: rgba(59, 135, 176, 0.3);;
}

.privacy-container .text-container p{
  font-weight: 100;
  z-index: -2 !important;
  margin-bottom: 7vmin;
}
.blender{
  width: 100%;
  height: 25vh;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(209, 239, 255, 0) 0%, #D1EEFF 60.42%);
}
.errorMsg{
  position: absolute;
  bottom: 0;
  color: rgb(232, 57, 57);
}
.statusMsg{
  position: absolute;
  bottom: 0;
  color: var(--dark-blue);
}
.login-form{
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-form h1{
  font-size: 3rem;
  color: #2F3759;
}
.login-form .form-row{
  width: 30%;
}
.login-form ::-webkit-input-placeholder{
  text-align: center;
}
.login-form a{
  color: #0000005C;
  line-height: 2em;
}
.login-form .btn-purple{
  font-size: 24px;
  padding: .2em 3em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.bot{
  position: relative;
}
.bot::after{
  content: '';
  width: 80%;
  height: 1px;
  background: #2F3759;
  position: absolute;
  bottom: 0;
  left: 10%;
  overflow-y: scroll;
}
.login-form p{
  margin-top: 1.5em;
}
.lnk-blue{
  color: #4CAAF4 !important;
  cursor: pointer;
}
.confirmation{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 40vh;
  width: 50vw;
  top: 25vh;
  left: 25vw;
  z-index: 10;
  background: white;
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  transform-origin: center;
  opacity: 0;
  transition: .2s ease-in-out;
  pointer-events: none;
}
.confirmation .btn-purple{
  padding: .5em 5em;
}
.popup{
  top: 30vh;
  opacity: 1;
  pointer-events: auto;
}
.reset p{
  color: #0000005C;
  margin-bottom: 2em;
}
.reset .errorMsg{
  position: relative;
}
.dashboard-container{
  max-width: min(90vw, 1270px);
  min-width: min(100vw,750px);
  max-height: min(75vh,735px);
  background: white;
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  border-radius: 10px;
  display: flex;
}
.dashboard-container .dashboard-menu{
  padding: 5rem 1.5rem;
  width: 25%;
  max-height: 100%;
  border-right: 1px solid rgba(47, 55, 89, 0.3);
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.dashboard-container .dashboard-menu h3{
  font-size: 1.8rem;
  color: var(--heading-dark);
}
.dashboard-container .dashboard-menu .menu{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.menu-item{
  cursor: pointer;
  transition: .1s ease-in-out;
}
.menu-item:hover{
  transform: translateX(5%);
}
.menu-item img{
  display: inline-block;
}
.menu-item p{
  display: inline-block;
  margin-left:1em;
  font-size: 1.3rem;
  color: var(--dark-blue);
}
.content{
  width: 74%;
  padding-top: 5rem;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 60vh;
}

.content::-webkit-scrollbar {
  width: 5px;
}
.content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
.content::-webkit-scrollbar-thumb {
  width: 20px;
  border-radius: 10px;
  background: rgba(59, 135, 176, 0.3);;
}
.content .header-dashboard{
  display: block;
  padding-left: 1.5rem;
  min-width: 40vw;
}
.banner{
  margin-top: 2rem;
  width: 100%;
  max-width: 100%;
  height: 22vh;
  background: var(--light-blue);
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}
.banner h3{
  font-size: 1.8rem;
  color: var(--heading-dark);
}
.banner .btn-purple{
  padding: .5em 3em;
  text-transform: capitalize;
  margin-top: 2em;
}
.banner img{
  display: block;
  max-width: 100%;
}
.activity{
  padding-top: 1em;
  max-width: 85%;
  margin: 0 auto;
}
.activity-info{
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.test-info{
  padding: 1rem;
  display: flex;
  align-items: center;
  background: var(--light-blue);
  border-radius: 10px;
  gap: 0.5rem;
  min-width: 100px;
  max-width: 100px;
}
.test-info h3{
  font-size: 13px;
  width: max-content;
}
.info{
  width: max-content;
}
.info p{
  font-size: 30px;
  font-weight: 500;
}
.test-info img{
  display: block;
  max-width: 100%;
    height: 100%;
}
.activity .transactions{
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}
.transactions .btn-purple{
  padding: 10px 20px;
}
.activity .transactions tr:first-of-type{
  background: var(--light-blue);
}
.activity .transactions tr:first-of-type th:first-child{
  border-top-left-radius: 10px;
}
.activity .transactions tr:first-of-type th:first-child{
  border-bottom-left-radius: 10px;
}
.activity .transactions tr:first-of-type th:last-child{
  border-top-right-radius: 10px;
}
.activity .transactions tr:first-of-type th:last-child{
  border-bottom-right-radius: 10px;
}
.activity .transactions tr:first-of-type th{
 padding: .5em;
}
.activity .transactions tr td{
  padding: .5em;
  text-align: center;
  font-weight: 500;
 }
 .acc-settings{
   width: 80%;
   margin-top: 5em;
   margin-left: 3em;
 }
 .disabled{
   background: white !important;
   border: 1px solid #0000005C !important;
    color: black !important;
    cursor: default;
    pointer-events: none;
  }
 .acc-settings .form-row{
   width: 100%;
   margin-top: 2em;
 }
 .acc-settings .form-row .input-wrapp{
  position: relative;
  width: 45%;
}
 .acc-settings .form-row .input-wrapp::before{
  content: 'Placeholder';
  position: absolute;
  top: -50%;
}
.acc-settings .form-row .input-half:first-child{
  border-top: 1px solid #ccc;
  width: 48%;
  margin-bottom: 1em;
  margin-top: -2em;
}
.acc-settings .form-row .btn-purple{
  padding: .5em 4em;
  font-size: 20px;
  font-weight: 500;
}
.acc-fname::before{
  content: 'First Name' !important;
}
.acc-lname::before{
  content: 'Last Name' !important;
}
.acc-phone::before{
  content: 'Phone Number' !important;
}
.acc-address::before{
  content: 'Address' !important;
}
.acc-state::before{
  content: 'State' !important;
}
.acc-zip::before{
  content: 'Zip Code' !important;
}
.acc-city::before{
  content: 'City' !important;
}
.acc-email::before{
  content: 'Email' !important;
}
.acc-pw::before{
  content: 'Password' !important;
}
.acc-pw-confirm::before{
  content: 'Password' !important;
}
.acc-license::before{
  content: 'ID#' !important;
}
.payment{
  max-width: 85%;
  margin-top: 1em;
}
.payment-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-radius: 10px;
  background: var(--light-blue);
  margin-bottom: 2em;
}
.payment-item .type{
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.payment-item .type img{
  display: block;
  max-width: 100%;
  height: 100%;
  margin-right: 2em;
}
.delete{
  color: var(--dark-blue);
  text-decoration: underline;
}
.a1{
  margin: 0 auto;
}
.drop-zone{
  text-align: center;
  width: 45%;
}
.drop-zone h1{
  text-transform: uppercase;
}
.drop-zone p{
  cursor: pointer;
}
.drop-zone p span{
  text-decoration: underline;
}
.DropZone{
  margin-top: 1rem;
  width: 100%;
  padding: 3rem 0;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #C8CBD3;
  border-radius: 10px;
  margin-bottom: 1em;
}
.DropZone .license{
  display: block;
  max-width: 50%;
}
.test-setup-container{
    width: 66vw;
    max-width: 1270px;
    min-width: min(100vw, 750px);
    background: white;
    box-shadow: 3px 3px 10px 0px #3b87b04d;
    border-radius: 10px;
} 
 .header{
  padding: 2.5rem 0;
  border-bottom: 1px solid #2F37594D;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.test-setup-container .header .number{
  font-size: 18px;
  display: flex;
  text-transform: capitalize;
  transition: .1s ease-in-out;
  user-select: none;
}
.test-setup-container .header .number span{
  aspect-ratio: 1 / 1;
  width: 20px;
  align-items: center;
  justify-content: center;
  padding: .5em 1em;
  font-family: 'Poppins';
  border-radius: 50%;
  background: #2F37594D;
  text-align: center;
  margin-right: 1em;
  color: white;
}
.test-setup{
  width: 100%;
  height: 85%;
  position: absolute;
  top: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.lnk-blue span{
  background: var(--dark-blue) !important;
}
.wrap-sync{
  min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.sync-box{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  text-align: center;
}
.react-code-input input{
  border: none !important;
  box-shadow: none !important;
  background: var(--light-blue) !important;
}
.footer::before{
  content: 'Please check your input before continuing';
  position: absolute;
  left: 10%;
  color: red;
  display: none;
  text-align: left;
}
.footer.required::before{
  display: inline;
}
.pdf-container{
  width: 65vw;
  max-width: 1270px;
  height: 70vh;
  max-height: 975px;  
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  background: white;
  border-radius: 10px;
  overflow-y: scroll;
  padding: 2em;
  display: flex;
  justify-content: center;
}
.pdf-container img{
  width: 900px;
}
.pdf{
  position: relative;
  height: max-content;
  width: max-content;
}
.pdf p{
  position: absolute;
  text-align: center;
}
.pdf input{
  position: absolute;
  display: flex;
}
.payment-form{
  width: 50%;
}
.payment-form .form-row{
  width: 100%;
  display: block;
}
.card-input{
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 10px;
  padding-right: 0;
  margin-bottom: 20px;
  transition: .2s ease-in-out;
}
.payment-container{
  width: 40vw;
  max-width: 1270px;
  height: 50vh;
  max-height: 975px;  
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  background: white;
  border-radius: 10px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.payment-form h1{
  margin-bottom: 2em;
}
.payment-form h3{
  margin-bottom: 1.5em;
}
.payment-form .btn-purple{
  padding: .5em 3em;
}
.d-flex{
  display: flex !important;
}

.btn-big{
  padding: .7rem 4rem;
  font-size: 1rem;
}
.btn-medium{
  font-size: 18px !important;
  padding: .6rem 1.5rem !important;
}
.test-prepare{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  gap: 1rem;
}
.test-prepare h1{
  text-align: left;
}
.row{
  width: 70%;
  min-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row > p{
  max-width: 70%;
  min-width: 700px;
}
.row .btn-outline:last-of-type{
  margin-left: 1em;
}
.btn-outline{
  background-color: white;
  border: 1px solid #ccc;
  color: ccc;
  border-radius: 10px;
  padding: .5em 2em;
  transition: .2s ease-in-out
}
.btn-yes:hover{
  border-color: green;
  color: green;
}
.btn-no:hover{
  border-color: red;
  color: red;
}
.btn-yes.active{
  border-color: green;
  color: green;
}
.btn-no.active{
  border-color: red;
  color: red;
}
.test-prepare select{
  border-radius: 10px;
  padding: .5em .3em;
}
.select{
  border-width: 2px;
  position: relative;
  padding: .5em 1em;
  display: flex;
  margin: 0 !important;
  z-index: 20;
  cursor: pointer;
}
.select-arrow{
  margin-left: 2em;
  transition: .1s ease-in-out;
}
.select-arrow svg{
  color: #333;
}
.select-dropdown{
  position: absolute;
  top: -100%;
  z-index: -1;
  max-width: 100%;
  left: -.2em;
  padding: .2em .2em;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  border-top: none;
  text-align: center;
  padding: 3rem .5rem 1rem .5rem;
  transition: .1s ease-in-out;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.select-dropdown .select-dropdown-item{
  font-size: 12px;
  cursor: pointer;
}
.select-open{
  top: 0;
  opacity: 1;
  pointer-events: auto;
}
.arrow-rotate{
  transform: rotate(180deg);
}
.prep-overlay{
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  background: #4D4AFF8F;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s ease-in-out;
  transform: scale(.9);
  opacity: 0;
  pointer-events: none;
  z-index: 101;
}
.prep-overlay.open{
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}
.sync-box .prep-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.prep-overlay-container{
  position: relative;
  width: 40vw;
  max-width: 1270px;
  max-height: 975px;  
  box-shadow: 3px 3px 10px 0px #3B87B04D;
  background: white;
  border-radius: 10px;
}
.close{
  width: max-content;
  font-size: 40px;
  background-color: var(--dark-purple);
  padding: .4em .8em;
  border-radius: 100%;
  color: white;
  position: absolute;
  right: -1em;
  top: -1em;
  cursor: pointer;
}
.prep-overlay h1{
  text-align: center;
}
.prep{
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.prep h3{
  width: 100%;
  text-align: center;
}
.prep-list{
  display: flex;
  align-items: flex-start;
 gap: 2rem;
}
.row .lnk-blue{
  cursor: pointer !important;
}
.row .btn-purple{
  padding: .5em 3em;
  font-size: 22px;
  margin:0 auto;
}
.prep p{
  max-width: 60%;
  margin: 0 auto;
}
.prep .btn-purple{
  padding: .5em 3em;
  font-size: 22px;
  margin: 0 auto;
}
.test-prepare > p{
  max-width: 70%;
}
.btn-big.btn-prep{
  padding: 1em 1em;
  transition: .1s ease-in-out;
}
.btn-big.active{
  background: #2F3759;
  color: white;
  flex-wrap: wrap;
}
.cnt{
  justify-content: center;
}
.test-prepare .acc-settings{
  margin-top: 2em;
}
.align-c{
  text-align: center !important;
}
.low-opacity{
  opacity: 0;
}
.col-fail{
  min-height: 80vh !important;
}
.col div p{
  text-align: center;
  margin-bottom: 1em;
}
.col > p{
  text-align: center;
  max-width: 70%;
}
.svp-text{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.svp-text p{
  text-align: center;
  max-width: 70%;
}
.svp-text p svg{
  color: var(--dark-purple);

}
.col > p svg{
  color: var(--dark-purple);
}
.btn-blue{
  background: var(--dark-blue) !important;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  white-space: nowrap;
}
.number-big{
  font-size: 50px;
  width: 20vw;
  aspect-ratio: 1/1;
  background-color: var(--heading-dark);
  border-radius: 100%;
  position: relative;
}
.number-big p{
  font-family: 'Poppins';
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.countdown{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 1000000;
}
.countdown-mobile{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 1000000;
}
.countdown-mobile .number-big{
  width: 50vw;
  aspect-ratio: 1/1;
  background-color: var(--heading-dark);
  border-radius: 100%;
  position: relative;
}
.countdown-mobile .number-big p{
  font-size: 50px !important;
  font-family: 'Poppins';
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.sync-box .prep{
  position: absolute;
  background: white;
  width: 100%;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:hsl(241, 100%, 65% , 0.56);
  z-index: 100;
  display: grid;
  place-items: center;
  -moz-transition: .2s  ease-in-out;
  transition: .2s  ease-in-out;
  transform: scale(0.9);
  filter: opacity(0);
  pointer-events: none;
}
.overlay .overlay-content{
  background: white;
  padding: 4rem;
  box-shadow: 3px 3px 10px rgba(59, 135, 176, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  
}
.overlay-open{
  transform: scale(1);
  filter: opacity(1);
  pointer-events: auto;
}
.overlay-content > p{
  max-width: 50ch;
  text-align: center;
}
.overlay-content > img{
  display: block;
  max-width: 100%;
}
.sync-box-grid{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 5rem 8rem 16rem 5rem 5rem;
  grid-template-columns: 10% 26% 26% 26% 10%; 
}
.sync-box-grid h1{
  grid-row-start: 1;
  grid-column-start: 2;
  grid-column-end: 5;
  justify-self: center;  
  align-self: center;
}
.sync-box-grid h3{
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 5;
  justify-self: center;  
  align-self: center;

}
.sync-box-grid .sync-QR{
  grid-row-start: 3;
  grid-column-start: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}
.sync-box-grid .sync-SMS{
  grid-row-start: 3;
  grid-column-start: 3;
  justify-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}
.sync-box-grid .sync-CODE{
  grid-row-start: 3;
  grid-column-start: 4;
  justify-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 1rem;
}
.sync-box-grid > p{
  grid-row-start: 4;
  grid-column-start: 2;
  grid-column-end: 5;
  justify-self: center;
  text-align: center;
}
.depth-perception-container{
  display: grid;
  place-items: center;
}
.depth-perception-container .prep{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  max-height: 60%;
  padding: 2rem;
  background: white;
  height: 100%;
  border-radius: 10px;
   background: white;
  z-index: 100;
  box-shadow: 3px 3px 10px rgba(59, 135, 176, 0.3);

}
.depth-perception-test{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(59, 135, 176, 0.3);
  overflow: hidden;
}
.preps{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  text-align: center;
  gap: 2rem
}
.depth-perception-popup{
  height: 100vh;
  width: 100%;
  background: white;
  z-index: 99999999999;
  position: absolute;
  display:flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 1rem;
}
.depth-perception-popup > div{
  display: flex;
  gap: 1rem;
}
.prep-test{
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  gap: 1rem;
}
.center{
  margin: 0 auto !important;
}
.post-overlay{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  z-index: 999999999999;
  gap: 2rem;
  transition: .2s ease-in-out;
  transform: scale(0.9);
  opacity: 0;
  pointer-events: none;
}
.post-overlay .buttons{
  display: flex;
  gap: 1rem;
}
.post-overlay.finished{
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}
.VF-prep-container{
  max-width: 80%;
  display: flex;
  gap: 2rem;
  align-items: center;
  margin: 0 auto;
}
#VF-tutorial{
  color: white;
  display: block;
  width: max-content;
  position:absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.red-text{
  color: #F16B6B !important;
}
.footer{
  width: 100vw;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  display: flex;
  background: white;
  mix-blend-mode: normal;
  box-shadow: 3px 3px 10px rgba(59, 135, 176, 0.3);
  justify-content: center;
}
.footer p {
  max-width: 60ch;
  display: block;
  text-align: left !important;
}
.footer input{
  aspect-ratio: 1/1;
  height: 1rem;
}
.preps input{
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 10px;
  padding-right: 0;
  margin-bottom: 20px;
  width:50ch !important;
  max-width:50ch !important;
  text-align: center;
}
.preps input:focus{
 outline: 2px solid var(--dark-blue);
}
.preps input::placeholder{
  color: var(--dark-blue);
}
#signature{
  font-family: 'Ephesis';
  font-size: 22px;
  font-weight: 700;
}
#signature::placeholder{
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: normal;
}
.text-left p{
  text-align: left;
}
.col.text-left{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.instruction-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}
.instruction-col h1{
  text-transform: capitalize;
  color: #1B2033;
}
.inctruction-col .btn-prep{
  border: none;
  outline: none;
}
.instruction-col .active{
  color: white;
  background-color: var(--heading-dark);
}
.instruction-col p{
  max-width: 30ch;
}
.instruction-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}
.screen-calibration-container{
    width: max(80vw,700px);
    height: max(70vh,500px);
    display: grid;
    grid-template-columns: 90% 10%;
    grid-template-rows: 40% 5% 55%;
  }
  .screen-calibration-container .btn-purple{
    grid-column-start: 2;
    grid-row-start: 2;
  }
.lottie{
  aspect-ratio: 1;
  max-width: 50vw;
}
.instruction-counter{
  display: flex;
  gap: 1rem;
}
.dot{
  aspect-ratio: 1;
  width: 10px;
  background: #2F37598A;
  border-radius: 50%;
  transition: .1s ease-in-out;
}
.dot.active{
  background-color: var(--heading-dark);
}

.col.text-center{
  text-align: center;
  color: var(--heading-dark);
  display: flex;
  align-items: center;
  gap: 3rem;
}
.text-center .btn-prep.active{
  color: white;
  background-color: var(--heading-dark);
}
.zoom{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 2px;
  padding-top: 2rem;
  left: 0;
  background-color:white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  z-index: 1000;
  transition: .2s ease-in-out;
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}
.zoom-lottie{
  max-width: 40%;
}
.zoom.open{
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}
.red-text{
  color: red;
}
.license-settings{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 1.5rem;
}
.license-settings .form-row{
  min-width: 35vw;
}
.resize-outline{
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
}
.resize::before{
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: 7px;
  border-top: 7px solid var(--dark-purple);
  border-left: 7px solid var(--dark-purple);
  height: 30%;
  width: 30%;
}
.resize::after{
  content: '';
  position: absolute;
  bottom: -5px;
  left: -5px;
  border-radius: 7px;
  border-bottom: 7px solid var(--dark-purple);
  border-left: 7px solid var(--dark-purple);
  height: 30%;
  width: 30%;
}
.resize-outline::before{
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 7px;
  border-top: 7px solid var(--dark-purple);
  border-right: 7px solid var(--dark-purple);
  height: 30%;
  width: 30%;
}
.resize-outline::after{
  content: '';
  position: absolute;
  bottom: -5px;
  right: -5px;
  border-radius: 7px;
  border-bottom: 7px solid var(--dark-purple);
  border-right: 7px solid var(--dark-purple);
  height: 30%;
  width: 30%;
}
.bad-input{
  border: 2px solid red
}
.bad-input:focus{
  outline: 1px solid red

}
@media only screen and (max-width: 1200px) {
header{
    padding-bottom: 1rem;
    width: 90vw;
  }
  header img{
    max-width: 200px;
  }
  header .btn-medium{
    font-size: 12px !important;
  }
  .header-links a {
    font-size: 12px;
  }
  ul{
    font-size: 12px;
  }
  p{
    font-size: 12px;
  }
  h3{
    font-size: 15px;
  }
  .btn-outline {
    padding: 0.3em 1.8em;
  }
  .test-prepare > p {
    max-width: 90%;
}
  .test-setup-container .header .number {
    font-size: 12px;
  }
  .test-setup-container .header{
    padding: 1.5rem
  }
  .test-setup-container .header .number p{
    font-size: 15px;
  }
  .test-prepare{
    padding: 1rem 0;
    grid-gap: .5rem;
  }
  .test-prepare h1{
    font-size: 20px
  }
  .row{
    width: 90%;
  }
  .select{
    padding: 0.5em 2.1em;
  }
  .select p{
    font-size: 10px !important;
  }
  .row p{
    font-size: 12px;
    max-width: 50ch;
    min-width: unset;
  }
  .select .select-dropdown {
    width: 91%;
    padding: 1.7rem 0.5rem 1rem 0.5rem
;
  }
  .select .select-dropdown-item p{
    font-size: 8px !important;
  }
  .close{
    padding: .5rem 1.2rem;
  }
  .row .btn-purple{
    font-size: 15px;
  }
/*  TEST SET UP  */
  .square{
  grid-template-rows: 4rem 4rem 9rem 3rem 5rem
  }
  .resize{
    margin-right: 2rem;
  }

  .react-code-input input{
    font-size: 20px !important; 
    width: 30px !important;
    height: 36px !important;

  }
  .sync-box-grid{
    grid-template-rows: 4rem 3rem 14rem 1rem 5rem;
  }
  .sync-box-grid h1{
    font-size: 30px;
  }
  .sync-box-grid h3{
    font-size: 15px;
  }
  .cali-wrap{
    padding: 5rem;
  }
  .VF-prep-container{
    max-width: 90%;
  }
  .start .left{
    padding: 1rem
  }
  .start .left button{
    width: unset;
  }
  .start .right{
    padding: 1rem;
  }
  .start .right .d-flex{
    justify-content: space-between;
  }
  .VF-prep-container .right .d-flex img{
    max-width: 100%;
  }
/*  HOME  */
  .section-1 h1,
  .section-2 h1,
  .section-3 h1,
  .section-4 h1{
    font-size: 40px !important;
  }
  .section-4 p{
    font-size: 15px !important
  }
  .section-1 .btn-purple {
    font-size: 20px;
    padding: 0.4em 4em;
    cursor: pointer;
  }
  .popup-bar{
    height: 80px;
  }
  .popup-container{
    height: calc( 100% + 50px);
  }
  .popup-container p {
    font-size: 15px;
    font-weight: 100;
    text-transform: capitalize;
}

.section-3 .img-card {
  min-height: 100px;
  width: 150px;
  padding: 1rem 0;
}
.section-3 .img-card .holder {
  height: 100px
;
}
.section-3 .img-card img {
  max-width: 80px;
}
.section-3 .img-card p {
  font-size: 20px;
  font-weight: 700;
}
.section-4 .card{
  gap: 1rem;
}
/*  DASHBOARD */
.dashboard-container .dashboard-menu {
  padding: 3rem 1.5rem;
  width: 25%;
  max-height: 100%;
  border-right: 1 px solid rgba(47, 55, 89, 0.3);
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}
.dashboard-container .dashboard-menu h3 {
  font-size: 1.2rem;
  color: var(--heading-dark);
}
.menu-item{
  display: flex;
}
.menu-item p {
  display: inline-block;
  margin-left: 1em;
  font-size: 0.8rem !important;
  color: var(--dark-blue);
}
.content{
  padding-top: 3rem;
}
.content h1{
  font-size: 1.2rem;
}
.banner{
  gap: 3rem;
}
.banner h3{
  font-size: 1.1rem;
}
.banner img{
  max-width: 25%;
}
.info p{
  font-size: 15px;
}
.test-info{
 padding: .7rem;
 min-width: 70px;
 max-width: 70px;
}
.test-info h3{
  font-size: 8px;
}
.test-info img {
  display: block;
  max-width: 20%;
  height: 100%;
}
.activity .transactions th{
  font-size: 12px;
}
.activity .transactions tr{
  font-size: 10px;
}
.acc-settings{
  margin-top: 3rem;
}
.acc-settings .form-row {
  width: 100%;
  margin-top: 1.5em;
}
.form-row input{
  padding:7px;
  font-size: 12px;
  margin-bottom: 10px;
}
.form-row select {
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 7px;
  padding-right: 0;
  margin-bottom: 10px;
  transition: .2s ease-in-out;
}
.acc-settings .form-row .input-wrapp::before {
  font-size: 12px;
}
.acc-settings .form-row .btn-purple {
  padding: 0.5em 2.5em !important;
  font-size: 15px !important;
  font-weight: 500;
}
.transactions .btn-purple {
  padding: 7px 15px;
  font-size: 10px;
}
.license-settings .form-row {
  min-width: 50vw;
}
/* Miscelaneus */
.prep-overlay-container{
  width: 60vw;
}
.preps input {
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 7px;
  padding-right: 0;
  margin-bottom: 10 px;
  width: 30ch !important;
  max-width: 50ch !important;
  text-align: center;
}
.payment-container{
  width: 60vw;
}
.payment-form{
  width: 70%;
} 
.payment-form h1 {
  margin-bottom: 1em;
}
.preps img{
  max-width: 20%;
}
.payment-container img{
  max-width: 20%;
}
.btn-medium{
  padding: .5rem 1rem !important;
  font-size: 12px !important;
}
.overlay .overlay-content{
  padding: 1rem;
}
.screen-calibration-container{
  max-width: 680px;
  max-height: 480px;
}
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .menu-item p{
    font-size: 1.2rem;
  }
  .row{
    width: 80%;
  }
  .row > p{
    max-width: 50ch;
    min-width: 30ch
  }
  .test-prepare >p{
    max-width: 80%;
  }
  .prep-test{
    gap: 2.3rem;
  }
  .test-prepare{
    gap: 2.5rem;
  }
  .sync-box-grid{
    grid-template-columns: 0% 33% 33% 33%;
  }
  .prep >p{
    max-width: 90%;
  }
}
@media only screen and (min-width: 1600px) and ( max-width: 1870px) {
  .row > p{
    min-width: 30ch ;
    max-width: 50ch;
  }
}
@media only screen and (max-height: 900px) {
  header{
    padding-bottom: 1rem;
    width: 90vw;
  }
  header img{
    max-width: 200px;
  }
  header .btn-medium{
    font-size: 12px !important;
  }
  .header-links a {
    font-size: 12px;
  }
  ul{
    font-size: 12px;
  }
  p{
    font-size: 12px;
  }
  h3{
    font-size: 15px;
  }
  .btn-outline {
    padding: 0.3em 1.8em;
  }
  .test-prepare > p {
    max-width: 90%;
}
  .test-setup-container .header .number {
    font-size: 12px;
  }
  .test-setup-container .header{
    padding: 1.5rem
  }
  .test-setup-container .header .number p{
    font-size: 15px;
  }
  .test-prepare{
    padding: 1rem 0;
    grid-gap: .5rem;
  }
  .test-prepare h1{
    font-size: 20px
  }
  .row{
    width: 90%;
  }
  .select{
    padding: 0.5em 2.1em;
  }
  .select p{
    font-size: 10px !important;
  }
  .row p{
    font-size: 12px;
    max-width: 50ch;
    min-width: unset;
  }
  .select .select-dropdown {
    width: 91%;
    padding: 1.7rem 0.5rem 1rem 0.5rem
;
  }
  .select .select-dropdown-item p{
    font-size: 8px !important;
  }
  .close{
    padding: .5rem 1.2rem;
  }
  .row .btn-purple{
    font-size: 15px;
  }
/*  TEST SET UP  */
  .square{
  grid-template-rows: 4rem 4rem 9rem 3rem 5rem
  }
  .resize{
    margin-right: 2rem;
  }

  .react-code-input input{
    font-size: 20px !important; 
    width: 30px !important;
    height: 36px !important;

  }
  .sync-box-grid{
    grid-template-rows: 4rem 3rem 14rem 1rem 5rem;
  }
  .sync-box-grid h1{
    font-size: 30px;
  }
  .sync-box-grid h3{
    font-size: 15px;
  }
  .cali-wrap{
    padding: 5rem;
  }
  .VF-prep-container{
    max-width: 90%;
  }
  .start .left{
    padding: 1rem
  }
  .start .left button{
    width: unset;
  }
  .start .right{
    padding: 1rem;
  }
  .start .right .d-flex{
    justify-content: space-between;
  }
  .VF-prep-container .right .d-flex img{
    max-width: 100%;
  }
/*  HOME  */
  .section-1 h1,
  .section-2 h1,
  .section-3 h1,
  .section-4 h1{
    font-size: 40px !important;
  }
  .section-4 p{
    font-size: 15px !important
  }
  .section-1 .btn-purple {
    font-size: 20px;
    padding: 0.4em 4em;
    cursor: pointer;
  }
  .popup-bar{
    height: 80px;
  }
  .popup-container{
    height: calc( 100% + 50px);
  }
  .popup-container p {
    font-size: 15px;
    font-weight: 100;
    text-transform: capitalize;
}
.popup-container img{
  max-width: 22%;
}
.section-2 p{
  max-width: 30%;
  font-size: 17px;
}
.section-3 .img-card {
  min-height: 100px;
  width: 150px;
  padding: 1rem 0;
}
.section-3 .img-card .holder {
  height: 100px
;
}
.section-3 .img-card img {
  max-width: 80px;
}
.section-3 .img-card p {
  font-size: 20px;
  font-weight: 700;
}
.section-4 .card{
  gap: 1rem;
}
/*  DASHBOARD */
.dashboard-container .dashboard-menu {
  padding: 3rem 1.5rem;
  width: 25%;
  max-height: 100%;
  border-right: 1 px solid rgba(47, 55, 89, 0.3);
  display: flex;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
}
.dashboard-container .dashboard-menu h3 {
  font-size: 1.2rem;
  color: var(--heading-dark);
}
.menu-item{
  display: flex;
}
.menu-item p {
  display: inline-block;
  margin-left: 1em;
  font-size: 0.8rem !important;
  color: var(--dark-blue);
}
.content{
  padding-top: 3rem;
}
.content h1{
  font-size: 1.2rem;
}
.banner{
  gap: 3rem;
}
.banner h3{
  font-size: 1.1rem;
}
.banner img{
  max-width: 25%;
}
.info p{
  font-size: 15px;
}
.test-info{
 padding: .7rem;
 min-width: 70px;
 max-width: 70px;
}
.test-info h3{
  font-size: 8px;
}
.test-info img {
  display: block;
  max-width: 20%;
  height: 100%;
}
.activity .transactions th{
  font-size: 12px;
}
.activity .transactions tr{
  font-size: 10px;
}
.acc-settings{
  margin-top: 3rem;
}
.acc-settings .form-row {
  width: 100%;
  margin-top: 1.5em;
}
.form-row input{
  padding:7px;
  font-size: 12px;
  margin-bottom: 10px;
}
.form-row select {
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 7px;
  padding-right: 0;
  margin-bottom: 10px;
  transition: .2s ease-in-out;
}
.acc-settings .form-row .input-wrapp::before {
  font-size: 12px;
}
.acc-settings .form-row .btn-purple {
  padding: 0.5em 2.5em !important;
  font-size: 15px !important;
  font-weight: 500;
}
.transactions .btn-purple {
  padding: 7px 15px;
  font-size: 10px;
}
.license-settings .form-row {
  min-width: 50vw;
}
/* Miscelaneus */
.prep-overlay-container{
  width: 60vw;
}
.preps input {
  background: var(--light-blue);
  border: none;
  border-radius: 10px;
  padding: 7px;
  padding-right: 0;
  margin-bottom: 10 px;
  width: 30ch !important;
  max-width: 50ch !important;
  text-align: center;
}
.payment-container{
  width: 60vw;
}
.payment-form{
  width: 70%;
} 
.payment-form h1 {
  margin-bottom: 1em;
}
.preps img{
  max-width: 20%;
}
.payment-container img{
  max-width: 20%;
}
.btn-medium{
  padding: .5rem 1rem !important;
  font-size: 12px !important;
}
.overlay .overlay-content{
  padding: 1rem;
}
.screen-calibration-container{
  max-width: 680px;
  max-height: 480px;
}
}

@media only screen and (min-width: 1250px) and (max-width: 1400px){
  .square{
    grid-template-rows: 4rem 4rem 9rem 3rem 8rem !important
    }
} 
@media only screen and (min-width: 1536px) and (max-width: 1600px){
  .square{
    grid-template-rows: 4rem 4rem 9rem 3rem 10rem !important
    }
} 