@media only screen and (max-width: 1200px) {
    .pdf-container img {
        width: 600px;
      }
    .NewYork input{
      font-size: 10px;
    }
    .NewYork .FirstName{
    top: 455px;
    left: 220px;
  }
  .NewYork .lastName{
      top: 455px;
      left: 30px;
  }
  .NewYork .DOBDay,
  .NewYork .DOBMonth,
  .NewYork .DOBYear{
    top: 455px;
  }
  .NewYork .DOBDay{
    left: 455px;
  }
  .NewYork .DOBMonth{
    left: 420px;
  }
  .NewYork .DOBYear{
    left: 490px;
  }
  .NewYork .MaleCheck{
    top: 450px;
    left: 528px;
    font-size: 20px;
  }
  .NewYork .FemaleCheck{
    top: 450px;
    left: 553px;
    font-size: 20px;
  }
  .transaction-pdf{
    z-index: -10;
    position: absolute;
    left: 2000%;
  }
  .NewYork .Address{
    left: 30px;
    top: 489px;
  }
  .NewYork .AptNum{
    top: 487px;
    left: 500px;
  }
  .NewYork .City{
    top: 520px;
    left: 30px;
  }
  .NewYork .State{
    top: 520px;
    left: 180px;
  }
  .NewYork .Country{
    top: 520px;
    left: 260px;
  }
  .NewYork .Zip{
    top: 520px;
    left: 380px;
  }
  .NewYork .DateM{
    top: 520px;
    left: 480px;
  }
  .NewYork .DateD{
    top: 520px;
    left: 510px;
  }
  .NewYork .DateY{
    top: 520px;
    left: 540px;
  }
  .NewYork .AcuityTestYes{
    top: 550px;
    font-size: 20px;
    left: 233px;
  }
  .NewYork .LenseCheckYes{
    top: 528px;
    left: 334px;
    font-size: 20px;
  }
  .NewYork .LenseCheckNo{
    top: 528px;
    left: 389px;
    font-size: 20px;    
}
  .NewYork .DoctorName{
    top: 590px;
    left: 30px;
  }
  .NewYork .DoctorAddress{
    top: 625px;
    left: 30px;
  }
  .NewYork .DoctorCity{
    top: 653px;
    left: 30px;
  }
  .NewYork .DoctorState{
    top: 653px;
    left: 235px;
  
  }
  .NewYork .DoctorCountry{
    top: 653px;
    left: 295px;
  }
  .NewYork .DoctorZip{
    top: 653px;
    left: 470px;
  }
  .NewYork .Valid12{
    top: 663px;
    left: 136px;
    font-size: 20px;
  }
  .NewYork .Valid6{
    top: 663px;
    left: 197px;
    font-size: 20px;
  }
  .NewYork .DoctorSign{
    font-family: 'Ephesis';
    font-size: 20px;
    top: 717px;
    left: 110px;
    font-weight: 700;
  }
  .NewYork .DoctorLicense{
    top: 720px;
    left: 400px;
  }
  }