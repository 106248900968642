@media only screen and (max-width: 1200px) {
    .florida input{
        font-size: 10px;
    }
    .florida .FirstName{
        top: 140px;
        left: 92px;
      }
    .florida .DOBDay{
        top: 140px;
        left: 260px;
      }
      .florida .Optometrist{
        top: 133px;
        left: 355px;
        font-size: 13px;
        font-weight: 600;
      }
      .florida .Ophthalmologist{
        top: 143px;
        left: 355px;
        font-size: 13px;
        font-weight: 600;
      }
      .florida .Address{
        left: 203px;
        top: 95px;
      }
      .florida .AptNum{
        top: 735px;
        left: 800px;
      }
      .florida .City{
        top: 95px;
        left: 430px;
      }
      .florida .State{
        top: 780px;
        left: 300px;
      }
      .florida .Country{
        top: 780px;
        left: 400px;
      }
      .florida .Zip{
        top: 780px;
        left: 575px;
      }
      .florida .Date{
        top: 188px;
        left: 70px;
      }
      .florida .Yes{
          left: 55px;
          font-size: 15px;
      }
      .florida .No{
          left: 91px;
          font-size: 15px;
      }
      .florida .Question-One{
        top: 350px;
      }
      .florida .Question-Two{
        top: 362px;
      }
      .florida .Question-Three{
        top: 382px;
      }
      .florida .Question-Four{
          top: 415px;
      }
      .florida .Question-Five{
          top: 427px;
      }
      .florida .Question-Six{
          top: 450px;
      }
      .florida .Question-Seven{
          top: 483px;
      }
      .florida .Question-Eight{
          top: 505px;
      }
      .florida .DoctorName{
        top: 60px;
        left: 450px;
      }
      .florida .DoctorAddress{
        top: 308px;
        left: 440px;
      }
      .florida .DoctorCity{
        top: 980px;
        left: 50px;
      }
      .florida .DoctorState{
        top: 980px;
        left: 360px;
      
      }
      .florida .DoctorCountry{
        top: 980px;
        left: 470px;
      }
      .florida .DoctorZip{
        top: 980px;
        left: 710px;
      }
      .florida .Valid12{
        top: 995px;
        left: 205px;
        font-size: 30px;
      }
      .florida .Valid6{
        top: 995px;
        left: 296px;
        font-size: 30px;
      }
      .florida .DoctorSign{
        font-family: 'Ephesis';
        font-size: 30px;
        top: 273px;
        left: 520px;
        font-weight: 700;
      }
      .florida .DoctorLicense{
        top: 308px;
        left: 180px;
      }
      .florida .UserSign{
        font-family: 'Ephesis';
        font-size: 30px;
        top: 110px;
        left: 260px;
        font-weight: 700;
    }
    .florida .LicenseNum{
        top: 75px;
        left: 480px;
    }
    .florida .DoctorPhone{
        top: 330px;
        left: 420px;
    }
    .florida .LensesIssued{
        top: 360px;
        left: 210px;
    }
    .florida .AssociateSign{
        font-family: 'Ephesis';
        font-size: 30px;
        top: 350px;
        left: 580px;
        font-weight: 700;
    }
    .florida .AssociateAddress{
        top: 380px;
        left: 510px;
    }
    .florida .AssociatePhone{
        top: 403px;
        left: 520px;
    }
    .florida .TableFirstRow{
        top: 310px;
    }
    .florida .TableSecondRow{
        top: 323px;
    }
    .florida .TableFirstRow.first{
        left: 260px;
    }
    .florida .TableFirstRow.second{
        left: 390px;
    }
    .florida .TableFirstRow.third{
        left: 515px;
    }
    .florida .TableSecondRow.first{
        left: 260px;
    }
    .florida .TableSecondRow.second{
        left: 390px;
    }
    .florida .TableSecondRow.third{
        left: 515px;
    }
    
    
}