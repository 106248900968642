.NorthCarolina .FirstName{
    top: 130px;
    left: 150px;
  }
  .NorthCarolina .lastName{
      top: 681px;
      left: 50px;
  }
  .NorthCarolina .DOBDay,
  .NorthCarolina .DOBMonth,
  .NorthCarolina .DOBYear{
    top: 681px;
  }
  .NorthCarolina .DOBDay{
    left: 695px;
  }
  .NorthCarolina .DOBMonth{
    left: 640px;
  }
  .NorthCarolina .DOBYear{
    left: 750px;
  }
  .NorthCarolina .MaleCheck{
    top: 658px;
    left: 797px;
    font-size: 30px;
  }
  .NorthCarolina .FemaleCheck{
    top: 658px;
    left: 835px;
    font-size: 30px;
  }
  .transaction-pdf{
    z-index: -10;
    position: absolute;
    left: 2000%;
  }
  .NorthCarolina .Address{
    left: 50px;
    top: 735px;
  }
  .NorthCarolina .AptNum{
    top: 735px;
    left: 800px;
  }
  .NorthCarolina .City{
    top: 780px;
    left: 50px;
  }
  .NorthCarolina .State{
    top: 780px;
    left: 300px;
  }
  .NorthCarolina .Country{
    top: 780px;
    left: 400px;
  }
  .NorthCarolina .Zip{
    top: 780px;
    left: 575px;
  }
  .NorthCarolina .Date{
    top: 1060px;
    left: 520px;
  }
 
  .NorthCarolina .AcuityTestYes{
    top: 793px;
    font-size: 30px;
    left: 500px;
  }
  .NorthCarolina .LenseCheckYes{
    top: 826px;
    left: 348px;
    font-size: 30px;
  }
  .NorthCarolina .LenseCheckNo{
    top: 826px;
    left: 424px;
    font-size: 30px;
  }
  .NorthCarolina .DoctorName{
    top: 130px;
    left: 550px;
  }
  .NorthCarolina .DoctorAddress{
    top: 1020px;
    left: 550px;
  }
  .NorthCarolina .DoctorCity{
    top: 980px;
    left: 50px;
  }
  .NorthCarolina .DoctorState{
    top: 980px;
    left: 360px;
  
  }
  .NorthCarolina .DoctorCountry{
    top: 980px;
    left: 470px;
  }
  .NorthCarolina .DoctorZip{
    top: 980px;
    left: 710px;
  }
  .NorthCarolina .Valid12{
    top: 995px;
    left: 205px;
    font-size: 30px;
  }
  .NorthCarolina .Valid6{
    top: 995px;
    left: 296px;
    font-size: 30px;
  }
  .NorthCarolina .DoctorSign{
    font-family: 'Ephesis';
    font-size: 30px;
    top: 1053px;
    left: 200px;
    font-weight: 700;
  }
  .NorthCarolina .DoctorLicense{
    top: 1020px;
    left: 422px;
  }
  .NorthCarolina .UserSign{
    font-family: 'Ephesis';
    font-size: 25px;
    top: 180px;
    left: 260px;
    font-weight: 700;
}
.NorthCarolina .LicenseNum{
    top: 186px;
    left: 620px;
}
.NorthCarolina .Parent{
    top: 215px;
    left: 260px;
}
.NorthCarolina .Phone{
    top: 215px;
    left: 600px;
}
.NorthCarolina .Diagnosis{
    top: 270px;
    left: 320px;
    font-weight: 600;
}
.NorthCarolina .Question-Two{
    top: 290px;
}
.NorthCarolina .Question-Two.One{
    left: 320px;
}
.NorthCarolina .Question-Two.Two{
    left: 425px;
}
.NorthCarolina .Question-Two.Three{
    left: 532px;
}
.NorthCarolina .Question-Three{
    top: 309px;
}
.NorthCarolina .Question-Three.One{
    left: 320px;
}
.NorthCarolina .Question-Three.Two{
    left: 425px;
}
.NorthCarolina .Question-Three.Three{
    left: 496px;
}
.NorthCarolina .Question-Three.Four{
    left: 585px;
}
.NorthCarolina .TableFirstRow{
    top: 368px;
}
.NorthCarolina .TableSecondRow{
    top: 405px;
}
.NorthCarolina .first{
    left: 365px;
}
.NorthCarolina .second{
    left: 500px;
}
.NorthCarolina .third{
    left: 620px;
}
.NorthCarolina .Question-Six{
    top: 465px;
    left: 532px;
}
.NorthCarolina .Question-Seven{
    top: 488px;
    left: 532px;
}
.NorthCarolina .Question-Eight{
    top: 540px;
    font-size: 15px;
}
.NorthCarolina .Question-Eight.One{
    left: 210px;
}
.NorthCarolina .Question-Eight.Two{
    left: 310px;
}
.NorthCarolina .Question-Eight.Three{
    left: 500px;
}
.NorthCarolina .Question-Eight.Four{
    left: 600px;
}
.NorthCarolina .Question-Eight-Check{
    top: 565px;
    left: 400px;
}
.NorthCarolina .Question-Nine {
    top: 600px;
    left: 160px;
    font-weight: 600;
}
.NorthCarolina .Question-Ten {
    top: 623px;
    left: 435px;
    font-weight: 600;
}
.NorthCarolina .Question-Sixteen{
    font-weight: 600;
    top: 773px;
    left: 160px;
}
.NorthCarolina .Question-Seventeen{
    font-weight: 600;
    top: 815px;
    left: 160px;
}
.NorthCarolina .Question-Eighteen{
    top: 845px;
    font-weight: 600;
    left: 690px;
}
.NorthCarolina .Question-Nineteen{
    top: 934px;
    left: 403px;
    font-weight: 600;
}
.NorthCarolina .Question-Nineteen.Six-Months{
    left: 320px;
}
.NorthCarolina .Question-Nineteen.Circle{
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid black;
    left: 478px;
    top: 940px;
}
.NorthCarolina .DoctorPhone{
    top: 1040px;
    left: 170px;
}
.NorthCarolina .DoctorFax{
    top: 1040px;
    left: 470px;
}
.NorthCarolina .DoctorName-bottom{
    top: 1020px;
    left: 170px;
}
.NorthCarolina .DoctorDeg{
    top: 1020px;
    left: 345px;
}